import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from "./components/Loader/Loader";
import AdminLayout from "./layouts/AdminLayout";

import GuestGuard from "./components/Auth/GuestGuard";
import AuthGuard from "./components/Auth/AuthGuard";
import AccessRightsGuard from "./components/Auth/AccessRightsGuard";

import CustomSecurityEncrypt from "./components/Security/CustomSecurityEncrypt";

import { BASE_URL } from "./config/constant";

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<Loader />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    <CustomSecurityEncrypt {...props}>
                                        {route.routes
                                            ? renderRoutes(route.routes)
                                            : <Component {...props}
                                                applicationID={route.applicationID}
                                                screenID={route.screenID} />}
                                    </CustomSecurityEncrypt>
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/auth/signin',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Auth/Signin/SignIn'))
    },
    {
        exact: true,
        path: '/404',
        applicationID: '',
        screenID: '',
        component: lazy(() => import('./views/Errors/NotFound404'))
    },
    {
        path: '*',
        layout: AdminLayout,
        guard: AuthGuard,
        routes: [
            //{
            //    exact: true,
            //    guard: AccessRightsGuard,
            //    path: '/app/home/dashboard',
            //    applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
            //    screenID: '5bfb3236-07bb-11ea-a23c-3ca82aa779fb',
            //    component: lazy(() => import('./views/Home/Dashboard/DashDefault'))
            //},
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/home/user_profile',
                applicationID: '',
                screenID: '',
                component: lazy(() => import('./views/Common/User_Profile'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/user_management/user_listing',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '5c0b9a5b-07bb-11ea-a23c-3ca82aa779fb',
                component: lazy(() => import('./views/System_Admin/User_Management/User_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/user_management/user_profile',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '5c2faa3f-07bb-11ea-a23c-3ca82aa779fb',
                component: lazy(() => import('./views/System_Admin/User_Management/User_Profile'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/user_management/user_role_listing',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '182e50a2-c681-4a23-8d76-241ffdf4dfa9',
                component: lazy(() => import('./views/System_Admin/Role_Management/Role_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/user_management/role_detail',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '182e50a2-c681-4a23-8d76-241ffdf4dfa9',
                component: lazy(() => import('./views/System_Admin/Role_Management/Role_Detail'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/scorecard_management/scorecard_listing',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '1b3b5394-9782-421f-9426-c28e56b57f5d',
                component: lazy(() => import('./views/System_Admin/Scorecard_Management/Scorecard_Listing'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/scorecard_management/scorecard_details',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: 'e4531709-49e7-4d21-a74a-524552b5068c',
                component: lazy(() => import('./views/System_Admin/Scorecard_Management/Scorecard_Detail'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/fiscal_year_management',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: 'acf347e5-eabb-4793-871b-f1331de512ab',
                component: lazy(() => import('./views/System_Admin/Fiscal_Year_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/email_template_management',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '78351bae-93a5-49c6-a6cd-023ae09b5a4c',
                component: lazy(() => import('./views/System_Admin/Email_Template_Management'))
            },
            {
                exact: true,
                guard: AccessRightsGuard,
                path: '/app/sysadmin/secure_credential_management',
                applicationID: '54f63072-07b8-11ea-a23c-3ca82aa779fb',
                screenID: '6715d2e8-d833-4c03-bcbe-3c814b0856f8',
                component: lazy(() => import('./views/System_Admin/Secure_Credential_Management'))
            },
            {
                path: '*',
                exact: true,
                applicationID: '',
                screenID: '',
                component: () => <Redirect to={BASE_URL} />
            }
        ]
    }
];

export default routes;
