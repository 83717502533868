import React, { useContext, useState, useEffect } from 'react';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import RequireForAccess, { ReactAccessProvider } from 'react-access';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from "./ChatList";
import { ConfigContext } from "../../../../contexts/ConfigContext";
import * as actionType from "../../../../store/actions";
import useAuth from '../../../../hooks/useAuth';
import Loader from "../../../../components/Loader/Loader";
import toast from '../../../../common/error_handler/Toast';

import AnimatedModal from '../../../../components/Modal/AnimatedModal';
import LanguageChangeModal from '../../../../components/Modal/LanguageChangeModal/LanguageChangeModal';
import ChangePasswordModal from '../../../../components/Modal/ChangePasswordModal/ChangePasswordModal';

import edit_language_icon from '../../../../assets/images/icon/language/edit_language_icon.png';

import Chinese from '../../../../assets/images/icon/language/Chinese.png';
import Malay from '../../../../assets/images/icon/language/Malay.png';
import English from '../../../../assets/images/icon/language/English.png';
import Japanese from '../../../../assets/images/icon/language/Japanese.png';

import { getUserPreferredLanguage_service, updateUserPreferredLanguage_service } from '../../../../services/Common/Common';

const NavRight = () => {
    const history = useHistory();
    const configContext = useContext(ConfigContext);
    const { language, languages, func_language_change, func_logout, access_rights } = useAuth();
    const functionAccessData = [...new Set(access_rights.map(p => p.functionID))];
    const { enable_language_change_flag, rtlLayout } = configContext.state;
    const { dispatch } = configContext;

    const [listOpen, setListOpen] = useState(false);
    const [english, setEnglish] = useState(false);
    const [malay, setMalay] = useState(false);
    const [chinese, setChinese] = useState(false);
    const [japanese, setJapanese] = useState(false);

    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(null);

    const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);

    const handleLogout = async () => {
        try {
            await func_logout();
        } catch (err) {
            console.error(err);
        }
    };

    const languageChange = async (language_code) => {

        var languageCode = window.sessionStorage.getItem('endevo_LanguageCode');
        var languageName = "COMMON.English";

        if (language_code == "languageCN") {
            setEnglish(false);
            setMalay(false);
            setChinese(true);
            setJapanese(false);
            languageName = "COMMON.Chinese";
        } else if (language_code == "languageMY") {
            setEnglish(false);
            setMalay(true);
            setChinese(false);
            setJapanese(false);
            languageName = "COMMON.Malay";
        } else if (language_code == "languageJP") {
            setEnglish(false);
            setMalay(false);
            setChinese(false);
            setJapanese(true);
            languageName = "COMMON.Japanese";
        } else {
            setEnglish(true);
            setMalay(false);
            setChinese(false);
            setJapanese(false);
            languageName = "COMMON.English";
        }

        /* if current language != selected language, then only change language */
        if (language_code !== languageCode) {
            var return_data = await updateUserPreferredLanguage_service(language_code);
            await func_language_change(language_code);

            var title = languages.filter(p => p.languageKey == "COMMON.Set Language As Default")["0"][language_code];
            title = title.replace("$LANGUAGE$", languages.filter(p => p.languageKey == languageName)["0"][language_code]);
            var message = "";

            if (return_data == "Success") {
                message = languages.filter(p => p.languageKey == "COMMON.Success")["0"][language_code];
                toast.success(title, message);
            }
            else {
                message = languages.filter(p => p.languageKey == "COMMON.Error")["0"][language_code];
                toast.error(title, message);
            }
        }
    }

    const getUserPreferredLanguage = async () => {

        var languageCode = window.sessionStorage.getItem('endevo_LanguageCode');
        var language_code = await getUserPreferredLanguage_service();

        if (language_code) {
            if (language_code == "languageCN") {
                setEnglish(false);
                setMalay(false);
                setChinese(true);
                setJapanese(false);
            } else if (language_code == "languageMY") {
                setEnglish(false);
                setMalay(true);
                setChinese(false);
                setJapanese(false);
            } else if (language_code == "languageJP") {
                setEnglish(false);
                setMalay(false);
                setChinese(false);
                setJapanese(true);
            } else {
                setEnglish(true);
                setMalay(false);
                setChinese(false);
                setJapanese(false);
            }
        }

        if (languageCode !== language_code) {
            await func_language_change(language_code);
        }
    }

    const handleShowEditLanguageFunction = () => {
        dispatch({ type: actionType.SHOW_EDIT_LANGUAGE })
    }

    const handleShowLanguageModal = (lang) => {
        setSelectedLanguage(lang);
        setShowLanguageModal(true);
        setShowPasswordChangeModal(false);
    }

    const handleShowPasswordChangeModal = () => {
        setShowLanguageModal(false);
        setShowPasswordChangeModal(true);
    }

    const handleUserProfile = () => {
        var urlPath = "/app/home/user_profile";

        history.push(urlPath);
    }

    useEffect(() => {
        const init = () => {
            getUserPreferredLanguage();
        };

        init();
    }, []);

    if (languages.length == 0) {
        return <Loader />;
    }

    return (
        <React.Fragment>
            <ListGroup as='ul' bsPrefix=' ' className="navbar-nav ml-auto" id='navbar-right'>
                <ListGroup.Item as='li' bsPrefix=' '>
                    <ReactAccessProvider permissions={functionAccessData}>
                        <RequireForAccess
                            permissions={['ffff08d1-8fa7-4bb7-86d8-cea207b13d3b']}
                        >
                            <Link to='#' id="link_enable_language_change" onClick={handleShowEditLanguageFunction}>
                                <img src={edit_language_icon} alt="Generic placeholder" className="custom-icon-size" />
                            </Link>
                        </RequireForAccess>
                    </ReactAccessProvider>
                </ListGroup.Item>
                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout}>
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <i className="custom-button-font-size fa fa-language" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification notification-scroll">
                            <div className="noti-head">
                                {languages.filter(p => p.languageKey == "COMMON.Language")["0"][language]}
                            </div>
                            <PerfectScrollbar>
                                <ListGroup as='ul' bsPrefix=' ' variant="flush" className="noti-body">
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification" onClick={() => languageChange("languageEN")}>
                                        <Media>
                                            <img className="img-radius" src={English} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        {languages.filter(p => p.languageKey == "COMMON.English")["0"][language]}
                                                    </strong>
                                                    <span className="n-time text-muted">
                                                        {english
                                                            ?
                                                            <>
                                                                <i className="fa fa-check" /> {languages.filter(p => p.languageKey == "COMMON.Selected")["0"][language]}
                                                            </>
                                                            : <></>
                                                        }
                                                    </span>
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification" onClick={() => languageChange("languageMY")}>
                                        <Media>
                                            <img className="img-radius" src={Malay} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        {languages.filter(p => p.languageKey == "COMMON.Malay")["0"][language]}
                                                    </strong>
                                                    <span className="n-time text-muted">
                                                        {malay
                                                            ?
                                                            <>
                                                                <i className="fa fa-check" /> {languages.filter(p => p.languageKey == "COMMON.Selected")["0"][language]}
                                                            </>
                                                            : <></>
                                                        }
                                                    </span>
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification" onClick={() => languageChange("languageCN")}>
                                        <Media>
                                            <img className="img-radius" src={Chinese} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        {languages.filter(p => p.languageKey == "COMMON.Chinese")["0"][language]}
                                                    </strong>
                                                    <span className="n-time text-muted">
                                                        {chinese
                                                            ?
                                                            <>
                                                                <i className="fa fa-check" /> {languages.filter(p => p.languageKey == "COMMON.Selected")["0"][language]}
                                                            </>
                                                            : <></>
                                                        }
                                                    </span>
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as='li' bsPrefix=' ' className="notification" onClick={() => languageChange("languageJP")}>
                                        <Media>
                                            <img className="img-radius" src={Japanese} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>
                                                        {languages.filter(p => p.languageKey == "COMMON.Japanese")["0"][language]}
                                                    </strong>
                                                    <span className="n-time text-muted">
                                                        {japanese
                                                            ?
                                                            <>
                                                                <i className="fa fa-check" /> {languages.filter(p => p.languageKey == "COMMON.Selected")["0"][language]}
                                                            </>
                                                            : <></>
                                                        }
                                                    </span>
                                                </p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                </ListGroup>
                            </PerfectScrollbar>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>

                {/*<ListGroup.Item as='li' bsPrefix=' '>*/}
                {/*    <Dropdown alignRight={!rtlLayout}>*/}
                {/*        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">*/}
                {/*            <i className="custom-button-font-size feather icon-bell icon" />*/}
                {/*        </Dropdown.Toggle>*/}
                {/*        <Dropdown.Menu alignRight className="notification notification-scroll">*/}
                {/*            <div className="noti-head">*/}
                {/*                <h6 className="d-inline-block m-b-0">Notifications</h6>*/}
                {/*                <div className="float-right">*/}
                {/*                    <Link to='#' className="m-r-10">mark as read</Link>*/}
                {/*                    <Link to='#'>clear all</Link>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*            <PerfectScrollbar>*/}
                {/*                <ListGroup as='ul' bsPrefix=' ' variant="flush" className="noti-body">*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="n-title">*/}
                {/*                        <p className="m-b-0">NEW</p>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">*/}
                {/*                        <Media>*/}
                {/*                            <img className="img-radius" src={English} alt="Generic placeholder" />*/}
                {/*                            <Media.Body>*/}
                {/*                                <p>*/}
                {/*                                    <strong>John Doe</strong>*/}
                {/*                                    <span className="n-time text-muted">*/}
                {/*                                        <i className="icon feather icon-clock m-r-10" />30 min*/}
                {/*                                    </span>*/}
                {/*                                </p>*/}
                {/*                                <p>New ticket Added</p>*/}
                {/*                            </Media.Body>*/}
                {/*                        </Media>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="n-title">*/}
                {/*                        <p className="m-b-0">EARLIER</p>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">*/}
                {/*                        <Media>*/}
                {/*                            <img className="img-radius" src={English} alt="Generic placeholder" />*/}
                {/*                            <Media.Body>*/}
                {/*                                <p>*/}
                {/*                                    <strong>Joseph William</strong>*/}
                {/*                                    <span className="n-time text-muted">*/}
                {/*                                        <i className="icon feather icon-clock m-r-10" />30 min*/}
                {/*                                    </span>*/}
                {/*                                </p>*/}
                {/*                                <p>Purchase New Theme and make payment</p>*/}
                {/*                            </Media.Body>*/}
                {/*                        </Media>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">*/}
                {/*                        <Media>*/}
                {/*                            <img className="img-radius" src={English} alt="Generic placeholder" />*/}
                {/*                            <Media.Body>*/}
                {/*                                <p>*/}
                {/*                                    <strong>Sara Soudein</strong>*/}
                {/*                                    <span className="n-time text-muted">*/}
                {/*                                        <i className="icon feather icon-clock m-r-10" />30 min*/}
                {/*                                    </span>*/}
                {/*                                </p>*/}
                {/*                                <p>currently login</p>*/}
                {/*                            </Media.Body>*/}
                {/*                        </Media>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                    <ListGroup.Item as='li' bsPrefix=' ' className="notification">*/}
                {/*                        <Media>*/}
                {/*                            <img className="img-radius" src={English} alt="Generic placeholder" />*/}
                {/*                            <Media.Body>*/}
                {/*                                <p>*/}
                {/*                                    <strong>Suzen</strong>*/}
                {/*                                    <span className="n-time text-muted">*/}
                {/*                                        <i className="icon feather icon-clock m-r-10" />yesterday*/}
                {/*                                    </span>*/}
                {/*                                </p>*/}
                {/*                                <p>Purchase New Theme and make payment</p>*/}
                {/*                            </Media.Body>*/}
                {/*                        </Media>*/}
                {/*                    </ListGroup.Item>*/}
                {/*                </ListGroup>*/}
                {/*            </PerfectScrollbar>*/}
                {/*            <div className="noti-footer">*/}
                {/*                <Link to='#'>show all</Link>*/}
                {/*            </div>*/}
                {/*        </Dropdown.Menu>*/}
                {/*    </Dropdown>*/}
                {/*</ListGroup.Item>*/}
                {/*<ListGroup.Item as='li' bsPrefix=' '>*/}
                {/*    <Dropdown>*/}
                {/*        <Dropdown.Toggle as={Link} variant='link' to='#' className="displayChatbox" onClick={() => setListOpen(true)}>*/}
                {/*            <i className="custom-button-font-size icon feather icon-mail" />*/}
                {/*        </Dropdown.Toggle>*/}
                {/*    </Dropdown>*/}
                {/*</ListGroup.Item>*/}

                <ListGroup.Item as='li' bsPrefix=' '>
                    <Dropdown alignRight={!rtlLayout} className="drp-user">
                        <Dropdown.Toggle as={Link} variant='link' to='#' id="dropdown-basic">
                            <i className="custom-button-font-size icon feather icon-settings" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={window.sessionStorage.getItem("endevo_profilePic")} className="img-radius" alt="User Profile" />
                                <span>
                                    {
                                        window.sessionStorage.getItem("endevo_userName")
                                    }
                                </span>
                                <Link to='#' className="dud-logout" title="Logout" onClick={handleLogout}>
                                    <i className="feather icon-log-out" />
                                </Link>
                            </div>
                            <ListGroup as='ul' bsPrefix=' ' variant='flush' className="pro-body">
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <Link to='#' className="dropdown-item">
                                        <span onClick={handleUserProfile}>
                                            <i className="feather icon-user" />
                                            {languages.filter(p => p.languageKey == "COMMON.Profile")["0"][language]}
                                        </span>
                                        &nbsp;
                                        <ReactAccessProvider permissions={enable_language_change_flag}>
                                            <RequireForAccess
                                                permissions={['true']}
                                            >
                                                <span id="link_chg_lang_profile" onClick={() => handleShowLanguageModal("COMMON.Profile")}>
                                                    <i className="fa fa-language" aria-hidden="true"></i>
                                                </span>
                                            </RequireForAccess>
                                        </ReactAccessProvider>
                                    </Link>
                                </ListGroup.Item>
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <Link to='#' className="dropdown-item">
                                        <span onClick={handleShowPasswordChangeModal}>
                                            <i className="feather icon-lock" />
                                            {languages.filter(p => p.languageKey == "COMMON.Change Password")["0"][language]}
                                        </span>
                                        &nbsp;
                                        <ReactAccessProvider permissions={enable_language_change_flag}>
                                            <RequireForAccess
                                                permissions={['true']}
                                            >
                                                <span id="link_chg_lang_change_password" onClick={() => handleShowLanguageModal("COMMON.Change Password")}>
                                                    <i className="fa fa-language" aria-hidden="true"></i>
                                                </span>
                                            </RequireForAccess>
                                        </ReactAccessProvider>
                                    </Link>
                                </ListGroup.Item>
                                <ListGroup.Item as='li' bsPrefix=' '>
                                    <Link to='#' className="dropdown-item">
                                        <span onClick={handleLogout}>
                                            <i className="feather icon-log-out" />
                                            {languages.filter(p => p.languageKey == "COMMON.Logout")["0"][language]}
                                        </span>
                                        &nbsp;
                                        <ReactAccessProvider permissions={enable_language_change_flag}>
                                            <RequireForAccess
                                                permissions={['true']}
                                            >
                                                <span id="link_chg_lang_logout" onClick={() => handleShowLanguageModal("COMMON.Logout")}>
                                                    <i className="fa fa-language" aria-hidden="true"></i>
                                                </span>
                                            </RequireForAccess>
                                        </ReactAccessProvider>
                                    </Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />

            <AnimatedModal visible={showPasswordChangeModal} onClose={() => setShowPasswordChangeModal(false)} animation="rotate">
                <ChangePasswordModal handleClosePasswordChangeModal={() => setShowPasswordChangeModal(false)} />
            </AnimatedModal>

            <AnimatedModal visible={showLanguageModal} onClose={() => setShowLanguageModal(false)} animation="rotate">
                <LanguageChangeModal editLang={selectedLanguage} handleCloseLanguageModal={() => setShowLanguageModal(false)} />
            </AnimatedModal>
        </React.Fragment>
    );
};

export default NavRight;
