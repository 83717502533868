import CryptoJS from "crypto-js";

const change_service = function () {
    var querystring = "endevo_refreshToken=" + window.sessionStorage.getItem("endevo_refreshToken");
    querystring = querystring + "&endevo_accessToken=" + window.sessionStorage.getItem("endevo_accessToken");
    querystring = querystring + "&endevo_firstLoginPasswordError=" + window.sessionStorage.getItem("endevo_firstLoginPasswordError");
    querystring = querystring + "&endevo_passwordValidityError=" + window.sessionStorage.getItem("endevo_passwordValidityError");
    querystring = querystring + "&endevo_userID=" + window.sessionStorage.getItem("endevo_userID");
    querystring = querystring + "&endevo_userName=" + window.sessionStorage.getItem("endevo_userName");
    querystring = querystring + "&endevo_profilePic=" + window.sessionStorage.getItem("endevo_profilePic");
    querystring = querystring + "&endevo_LanguageCode=" + window.sessionStorage.getItem("endevo_LanguageCode");
    querystring = querystring + "&endevo_userWorkingEmail=" + window.sessionStorage.getItem("endevo_userWorkingEmail");
    querystring = querystring + "&endevo_maxTotalFileSize=" + window.sessionStorage.getItem("endevo_maxTotalFileSize");
    querystring = querystring + "&endevo_maxFiles=" + window.sessionStorage.getItem("endevo_maxFiles");
    querystring = querystring + "&endevo_actionPlanName=" + window.sessionStorage.getItem("endevo_actionPlanName");
    querystring = querystring + "&endevo_targetValue=" + window.sessionStorage.getItem("endevo_targetValue");
    querystring = querystring + "&endevo_triggerValue=" + window.sessionStorage.getItem("endevo_triggerValue");
    querystring = querystring + "&endevo_incidentReportingName=" + window.sessionStorage.getItem("endevo_incidentReportingName");

    var encrpyt_Token = CryptoJS.AES.encrypt(querystring, process.env.REACT_APP_ENDEVO_SHARE_SECRET_KEY);
    return encrpyt_Token;
}

export default change_service;
